import React from "react";
import NFOModals, { ModalTypes } from "../../../Components/NFOModals";

interface RulesModalProps {
  isVisible: boolean;
  onClose: (value: boolean) => void;
}

const RulesModal: React.FC<RulesModalProps> = ({ isVisible, onClose }) => {
  const rules = [
    "Your exam consists of 25 questions.",
    "Each exam is for a duration of 30 minutes.",
    "The exam includes both multiple-choice and multiple-select questions. You will be able to find a tag below each question indicating the same.",
    "The exam will auto submit once the provided time has lapsed.",
    "Switching of tabs is not allowed during the exam. ",
  ];
  return (
    <NFOModals
      modalType={ModalTypes.GeneralModal}
      isVisible={isVisible}
      onClose={() => {
        onClose(false);
      }}
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,

            width: "350px",
            border: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div
            style={{
              color: "rgba(44, 106, 177, 1)",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Rules of the Mock-Olympiad
          </div>
          <div
            style={{
              justifyContent: "flex-start",
            }}
          >
            {rules.map((item: any, index: number) => {
              return (
                <div
                  key={`${index + item}`}
                  style={{
                    color: "rgba(44, 106, 177, 1)",
                    padding: "10px",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >{`${index + 1}. `}{item}</div>
              );
            })}
          </div>
          <div style={{ marginTop: 20 }}>
            <button
              className="cont"
              onClick={() => {
                onClose(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </>
    </NFOModals>
  );
};

export default RulesModal;
