import React, { useContext, useEffect, useState } from "react";
import "./AnswerAnalysisComponent.css";
import RenderMobileButtons from "../../../Components/RenderMobileButtons";
import RenderButtonsForAnswerSwitch from "./RenderButtonsForQuestionSwitch";
import { AuthContext } from "../../../App";
import { ExamResult } from "../../../Utils/DefinitionsInterfaces";
import SolutionAndReasoningCards from "./SolutionAndReasoningCards";
import MobileSolutionAndReasoningCards from "../MobileComponents/MobileSolutionAndReasoningCards";

const AnswerAnalysisComponent = () => {
  const {
    auth: {
      school_name,
      student_name,
      email,
      class_numerical,
      mobile_number,
      uuid,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const savedCurrentSolutionIndex = localStorage.getItem(
    "currentSolutionIndex"
  );
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState(
    savedCurrentSolutionIndex ? parseInt(savedCurrentSolutionIndex) : 0
  );

  useEffect(() => {
    localStorage.setItem(
      "currentSolutionIndex",
      currentSolutionIndex.toString()
    );
  }, [currentSolutionIndex]);

  const handleButtonClick = (buttonNumber: number) => {
    setCurrentSolutionIndex(buttonNumber - 1);
  };

  return (
    <div className="answerAnalysisContainer">
      <div className="answerAnalysisTitle">Answer key & Reasoning</div>
      <div className="mobile-hr-line">
        <hr
          style={{
            width: "100%",
            overflow: "scroll",
          }}
        />
      </div>
      <RenderButtonsForAnswerSwitch
        classname="analysis-buttons-matrix"
        stateCurrentSolutionIndex={currentSolutionIndex}
        cbHandleButtonClick={handleButtonClick}
      />
      <SolutionAndReasoningCards currentSolutionIndex={currentSolutionIndex} />
      <MobileSolutionAndReasoningCards
        currentSolutionIndex={currentSolutionIndex}
      />
    </div>
  );
};

export default AnswerAnalysisComponent;
