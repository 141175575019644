import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

export enum ModalTypes {
  GeneralModal = "GeneralModal",
}

interface NFOModalPropTypes {
  modalType: ModalTypes;
  children: React.ReactElement;
  isVisible: boolean;
  onClose: () => void;
}

interface GeneralModalTypes {
  children: React.ReactElement;
  isVisible: boolean;
  onClose: () => void;
}

/**
 * General Modal component created for modals
 * @param param0
 * @returns
 */
const GeneralModal: React.FC<GeneralModalTypes> = ({
  isVisible,
  onClose,
  children,
}) => {
  return (
    <Modal
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      open={isVisible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {children}
    </Modal>
  );
};

const NFOModals: React.FC<NFOModalPropTypes> = (props) => {
  switch (props.modalType) {
    case ModalTypes.GeneralModal:
      return (
        <GeneralModal
          children={props.children}
          isVisible={props.isVisible}
          onClose={props.onClose}
        />
      );
    default:
      return (
        <GeneralModal
          children={props.children}
          isVisible={props.isVisible}
          onClose={props.onClose}
        />
      );
  }
};

NFOModals.propTypes = {};

export default NFOModals;
