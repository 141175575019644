/**
 * 
 * @param data The string to be encoded
 * @returns Encoded String
 */
export const customEncoder = (data: string) => {
  const encodedData = btoa(encodeURIComponent(data));
  return encodedData;
}

/**
 * 
 * @param encodedData The String to be Decoded
 * @returns Decoded String
 */
export const customDecoder = (encodedData: string) => {
  const decodedData = decodeURIComponent(atob(encodedData));
  return decodedData;
}
