import React, { useEffect } from "react";
import "./PdfPreview.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";

import {
  DocumentAskPasswordEvent,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import "../../../node_modules/@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../Utils/api/supabaseClient";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

function PdfPreview() {
  const [pdfFile, setPDFFile] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { type, classValue } = location.state;
  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  const folderPath = (type: string) => {
    switch (type) {
      case "examrules":
        return `examrules/exam_rules.pdf`;
      case "guidebook":
        return `guidebooks/grade${classValue}/guidebook_${classValue}_protected.pdf`;
      case "workbook":
        return `workbooks/grade${classValue}/workbook_${classValue}_protected.pdf`;
      case "nationalGuidebookpreview":
        return `nationalGuideBooks/grade${classValue}/nationalguidebooksprev_${classValue}.pdf`;
      case "nationalGuidebookFull":
        return `nationalGuideBooks/grade${classValue}/nationalguidebooksfull_${classValue}.pdf`;
      default:
        return `examrules/exam_rules.pdf`;
    }
  };

  const onDocumentAskPassword = (e: DocumentAskPasswordEvent) => {
    switch (type) {
      case "examrules":
        return;
      case "guidebook":
        return e.verifyPassword("J4NY]fy[1#!66#6");
      case "workbook":
        return e.verifyPassword("J4NY]fy[1#!66#6");
      case "nationalGuidebookpreview":
        return e.verifyPassword("J4NY]fy[1#!66#6");
      case "nationalGuidebookFull":
        return e.verifyPassword("Z&58#88wJ-k-Qs#");
      default:
        return `examrules/exam_rules.pdf`;
    }
  };

  const fetchPdf = async () => {
    const { data } = await supabase.storage
      .from("nfo_storage")
      .getPublicUrl(folderPath(type));
    if (data) {
      setPDFFile(data?.publicUrl);
    }
  };

  useEffect(() => {
    fetchPdf();
  }, [location.state]);

  const fileType = ["application/pdf"];
  return (
    <div className="body" onContextMenu={(e) => e.preventDefault()}>
      {/* <img src={NFOLogo} alt="logo" className="logo" /> */}
      <div
        onClick={() => {
          navigate("/info");
        }}
        className="header"
        onContextMenu={(e) => e.preventDefault()}
      >
        <b className="back">&larr; Back to Portal</b>
        {/* <img src={background} alt="background" className="bg"></img> */}
      </div>

      <div className="container" onContextMenu={(e) => e.preventDefault()}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Zoom levels={[0.8, 1.2, 1.6, 2.4, 3.2]} />
        </div>
        <div
          className="pdf-container"
          onContextMenu={(e) => e.preventDefault()}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <div
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "10px",
                boxShadow: "20px",
              }}
            >
              {pdfFile ? (
                <Viewer
                  plugins={[zoomPluginInstance]}
                  onDocumentAskPassword={onDocumentAskPassword}
                  renderProtectedView={() => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#000",
                          flex: 1,
                          display: "flex",
                        }}
                      ></div>
                    );
                  }}
                  fileUrl={pdfFile}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Worker>
        </div>
      </div>
    </div>
  );
}

export default PdfPreview;
