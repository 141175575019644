import React, { useContext, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";

import "./Complete.css";
import { NFO, Ellipse } from "../../Assets/images";
import SummaryCard from "./components/SummaryCard";
import { AuthContext } from "../../App";
import AnswerAnalysisComponent from "./components/AnswerAnalysisComponent";
import NFOButtons, { NFOButtonTypes } from "../../Components/NFOButtons";
import NFOModals from "../../Components/NFOModals";
import PurchaseModal from "./components/Modals/PurchaseModal";
import {
  fetchSamplePapers,
  getCompletedExamResult,
  getPaymentLinkForOrder,
  requestUnlockOlympiad,
} from "../../Utils/MockQuizAPIs/MockQuizAPIs";
import {
  hardCodedStudentId,
  merchantLogoString,
  quizItemId,
} from "../../Utils/constants";
import {
  ApiResponseGetPaymentLink,
  ExamResult,
} from "../../Utils/DefinitionsInterfaces";
import PageFooter from "../../Components/PageFooter";
import MobileSummaryCard from "./MobileComponents/MobileSummaryCard";
import CompletePageHeader from "./components/CompletePageHeader";
import { AxiosResponse } from "axios";
import NudgeModal from "./components/Modals/NudgeModal";

interface User {
  uuid: string;
  school_name: string;
  student_name: string;
  mobile_number: string;
  email: string;
  class: string;
  class_numerical: number;
}

const ITEM_ID = 2

const ExamComplete = () => {
  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  const [purchaseOlympiadNudge, setPurchaseOlympiadNudge] = useState(false);
  const [showPurchaseOlympiadModal, setShowPurchaseOlympiadModal] =
    useState(false);

  let backButtonTextStr = "Back";
  let backButtonNavigateStr = "/dashboard";
  if (state && state.backButtonTextStr && state.backButtonNavigateStr) {
    backButtonTextStr = state.backButtonTextStr;
    backButtonNavigateStr = state.backButtonNavigateStr;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  useEffect(() => {
    const fetchData = async () => {
      const samplePaperIDStr = localStorage.getItem("samplePaperID");
      if (samplePaperIDStr) {
        try {
          const response = await getCompletedExamResult(
            parseInt(samplePaperIDStr)
          );
          if (response.status === 200 && response.data && response.data.data) {
            const examResultAnalysis = response.data.data;
            console.log(
              "Received ExamResults. Setting State",
              examResultAnalysis
            );
            setStateQuizAnalysis(examResultAnalysis);
          }
        } catch (error) {
          console.error("Error fetching Sample Papers Metadata:", error);
        }
      } else {
        console.log(
          "No saved samplePaperID found. Using previous data, if available."
        );
      }
    };
    fetchData();

    return () => {
      const savedCurrSol = localStorage.getItem("currentSolutionIndex");
      if (savedCurrSol !== null) {
        localStorage.removeItem("currentSolutionIndex");
      }
    };
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleTakeAnotherTest = async (): Promise<void> => {
    try {
      const response = await fetchSamplePapers();
      if (response.status === 200 && response.data && response.data.data) {
        const QPArray = response.data.data;
        let foundSamplePaper = false,
          hasUserPaid = false;

        for (let i = 0; !foundSamplePaper && i < QPArray.length; i++) {
          hasUserPaid =
            i === 0
              ? QPArray[i].paidForMockOlympiad
              : hasUserPaid && QPArray[i].paidForMockOlympiad;
          if (
            QPArray[i].examTaken === false &&
            QPArray[i].paid === false &&
            10 !== QPArray[i].samplePaperID
          ) {
            console.log("found Sample paper ID = ", QPArray[i].samplePaperID);
            foundSamplePaper = true;
          } else if (
            QPArray[i].examTaken === false &&
            QPArray[i].paid === true &&
            QPArray[i].paidForMockOlympiad === true &&
            10 !== QPArray[i].samplePaperID
          ) {
            console.log("found Sample paper ID = ", QPArray[i].samplePaperID);
            foundSamplePaper = true;
          }
        }
        if (foundSamplePaper) {
          console.log("Found Sample Paper, so going to subscribed Info Page");
          navigate("/subscribedInfo");
        } else if (hasUserPaid) {
          console.log(
            "User has taken all paid tests too. Taking the user to subscribedInfo page"
          );
          navigate("subscribedInfo");
        } else {
          handleOpenModal();
        }
      } else {
        throw "Response Error during fetching sample papers' metadata";
      }
    } catch (error) {
      console.error("Error fetching Sample Papers Metadata:", error);
    }
  };

  const checkIfUserHasBoughtOlympiad = async (): Promise<void> => {
    try {
      const response = await fetchSamplePapers();
      if (response.status === 200 && response.data && response.data.data) {
        const QPArray = response.data.data;
        let foundSamplePaper = false,
          hasUserPaid = false;

        for (let i = 0; !foundSamplePaper && i < QPArray.length; i++) {
          hasUserPaid =
            i === 0
              ? QPArray[i].paidForMockOlympiad
              : hasUserPaid && QPArray[i].paidForMockOlympiad;
          if (
            QPArray[i].examTaken === false &&
            QPArray[i].paid === false &&
            10 !== QPArray[i].samplePaperID
          ) {
            console.log("found Sample paper ID = ", QPArray[i].samplePaperID);
            foundSamplePaper = true;
          } else if (
            QPArray[i].examTaken === false &&
            QPArray[i].paid === true &&
            QPArray[i].paidForMockOlympiad === true &&
            10 !== QPArray[i].samplePaperID
          ) {
            console.log("found Sample paper ID = ", QPArray[i].samplePaperID);
            foundSamplePaper = true;
          }
        }
        if (foundSamplePaper) {
          console.log("Found Sample Paper, so going to subscribed Info Page");
          navigate("/subscribedInfo");
        } else if (hasUserPaid) {
          console.log(
            "User has taken all paid tests too. Taking the user to subscribedInfo page"
          );
          navigate("/subscribedInfo");
        } else if (!purchaseOlympiadNudge) {
          setShowPurchaseOlympiadModal(true);
        } else {
          navigate(backButtonNavigateStr);
        }
      } else {
        throw "Response Error during fetching sample papers' metadata";
      }
    } catch (error) {
      console.error("Error fetching Sample Papers Metadata:", error);
    }
  };

  //Placeholder function to make the payment and then return
  // const purchaseTests = (studentId: string): boolean => {
  //   return true;
  // };

  // const handlePurchaseTests = async (): Promise<void> => {
  //   const areMocksPurchased = purchaseTests(student_username);
  //   if (areMocksPurchased) {
  //     try {
  //       const responseUnlockOlympiad = await requestUnlockOlympiad(
  //         student_username
  //       );
  //       if(responseUnlockOlympiad.status === 200)
  //       {
  //         navigate("/subscribedInfo");
  //       }
  //       else {
  //         throw("Error in Unlock Olympiad Response");
  //       }
  //     } catch (error) {
  //       console.log("Error unlocking Mock olympiad. Error : ", error);
  //       handleCloseModal();
  //     }
  //   } else {
  //     console.log("Payment not done. Purchase failed");
  //     handleCloseModal();
  //   }
  // };

  const getSessionIdBD = async (): Promise<
    AxiosResponse<ApiResponseGetPaymentLink>
  > => {
    let orderNote = quizItemId; //"51"
    // orderNote = "27,28";
    const RequestData = {
      orderNote: orderNote,
    };
    let responseGetLinks = await getPaymentLinkForOrder(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const handleCheckoutNavigate = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      openInSameTab(
        `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${ITEM_ID}&token=${token}&userName=${student_username}`
      );
    } else {
      console.log("Access token not found in localstorage");
    }
  };

  const handlePaymentClick = async () => {
    handleCloseModal();
    try {
      const response = await getSessionIdBD();
      //@ts-ignore
      console.log(response.data.data.links[1].parameters.bdorderid);
      //@ts-ignore
      if (response.data.status == "Success") {
        var flow_config = {
          //@ts-ignore
          merchantId: response.data.data.links[1].parameters.mercid,
          //@ts-ignore
          bdOrderId: response.data.data.links[1].parameters.bdorderid,
          //@ts-ignore
          authToken: response.data.data.links[1].headers.authorization,
          childWindow: true,
          //@ts-ignore
          returnUrl: `https://olympiad.nationalfinanceolympiad.com/subscribedInfo/?order_id=${response.data.data.orderID}`,
          retryCount: 3,
          prefs: {
            payment_categories: ["card", "nb"],
            allowed_bins: ["540000", "400000"],
          },
          netBanking: {
            showPopularBanks: "Y",
            popularBanks: ["Kotak Bank", " AXIS Bank [Retail]"],
          },
        };

        var responseHandler = function (txn: {
          status: string;
          response: string;
        }) {
          console.log("callback received status:: ", txn.status);
          console.log("callback received response:: ", txn.response);
        };

        var theme_config = {
          sdkPrimaryColor: "#69068a",
          sdkAccentColor: "#cf5df5",
          sdkBackgroundColor: "#f2caff",
          sdkBannerColor: "#982cbb",
        };

        var config = {
          responseHandler: responseHandler,
          merchantLogo: merchantLogoString,
          flowConfig: flow_config,
          flowType: "payments",
          themeConfig: theme_config,
        };
        //@ts-ignore
        if (window.loadBillDeskSdk) {
          // Assuming you have a config object

          //@ts-ignore
          window.loadBillDeskSdk(config);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formatTime = (timeStr: string) => {
    const [mins, seconds] = timeStr.split(":");
    return ` ${mins} min ${seconds} sec`;
  };

  return (
    <div className="completePageContainer">
      <div className="completeBodyContainer">
        <div className="head-complete-page">
          <div className="backToDashboard">
            <NFOButtons
              type={NFOButtonTypes.GeneralButton}
              onButtonClick={() => {
                const savedSampleID = localStorage.getItem("samplePaperID");
                if (savedSampleID !== null) {
                  localStorage.removeItem("samplePaperID");
                }
                checkIfUserHasBoughtOlympiad();
              }}
              buttonText={"< " + backButtonTextStr}
              buttonColor="#E0EDFB"
              textColor="#2C6AB1"
              disableElevation={true}
              imagePostion="left"
              fontSize="24px"
              buttonStyle={{
                padding: "30px 30px",
              }}
              style={{
                justifyContent: "center",
              }}
              imageStyle={{
                width: "30px",
                marginRight: "0px",
              }}
            />
          </div>
          <CompletePageHeader />
          <div className="mobile-backToDashboard">
            <NFOButtons
              type={NFOButtonTypes.GeneralButton}
              onButtonClick={() => {
                const savedSampleID = localStorage.getItem("samplePaperID");
                if (savedSampleID !== null) {
                  localStorage.removeItem("samplePaperID");
                }
                checkIfUserHasBoughtOlympiad();
              }}
              buttonText={"< " + backButtonTextStr}
              buttonColor="#63656726"
              textColor="#2C6AB1"
              disableElevation={true}
              imagePostion="left"
              style={{
                justifyContent: "center",
              }}
              imageStyle={{
                width: "30px",
                marginRight: "0px",
              }}
            />
          </div>
        </div>
        <div className="completeContainerAfterHead">
          <SummaryCard
            timeTaken={formatTime(stateQuizAnalysis.timeTaken)}
            questionsAnswered={stateQuizAnalysis.questionsAttempted}
            examsLeft={stateQuizAnalysis.examsLeft}
            overallPercentage={stateQuizAnalysis.percentage}
            totalCorrectAnswerGiven={stateQuizAnalysis.totalCorrectAnswerGiven}
            totalQuestion={stateQuizAnalysis.totalQuestion}
            cbHandleTakeAnotherTest={handleTakeAnotherTest}
          />
          <MobileSummaryCard
            timeTaken={formatTime(stateQuizAnalysis.timeTaken)}
            questionsAnswered={stateQuizAnalysis.questionsAttempted}
            examsLeft={stateQuizAnalysis.examsLeft}
            overallPercentage={stateQuizAnalysis.percentage}
            totalCorrectAnswerGiven={stateQuizAnalysis.totalCorrectAnswerGiven}
            totalQuestion={stateQuizAnalysis.totalQuestion}
            cbHandleTakeAnotherTest={handleTakeAnotherTest}
          />
          {stateQuizAnalysis && stateQuizAnalysis.totalQuestion > 0 && (
            <AnswerAnalysisComponent />
          )}
        </div>
        <PageFooter />
      </div>
      <PurchaseModal
        primaryButtonText="Go Back"
        secondaryButtonText="Unlock Now"
        headerText="Unlock 11 Question Papers for your Olympiad Simulator"
        isVisible={isModalOpen}
        onClickPrimaryButton={() => {
          handleCloseModal();
        }}
        onClickSecondaryButton={handleCheckoutNavigate}
      />
      <NudgeModal
        primaryButtonText="Maybe Later"
        secondaryButtonText="Buy Now"
        headerText="Unlock 11 new exam papers at just ₹27 each."
        isVisible={showPurchaseOlympiadModal}
        onClickPrimaryButton={() => {
          setPurchaseOlympiadNudge(true);
          setShowPurchaseOlympiadModal(false);
          navigate(backButtonNavigateStr);
        }}
        onClickSecondaryButton={handleCheckoutNavigate}
      />
    </div>
  );
};

export default ExamComplete;
