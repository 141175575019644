import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NFO,
  NFOLogo2x,
  Ellipse,
  QPs3_Olympiad,
  PhoneIcon,
  alert,
  x_circle,
  questionMark,
  MoneyLogo,
  FinancialPlanningLogo,
  TaxPlanningLogo,
  InvestmentLogo,
  LoansAndCreditLogo,
  BankingLogo,
} from "../../../Assets/images";
import "./BreakdownSection.css";
import NFOButtons, { NFOButtonTypes } from "../../../Components/NFOButtons";
import PageFooter from "../../../Components/PageFooter";
import SectionalCards from "./SectionalCards";
import { AuthContext } from "../../../App";
import { getTopicWiseExamResult } from "../../../Utils/MockQuizAPIs/MockQuizAPIs";
import { ExamStatsTopicWise } from "../../../Utils/DefinitionsInterfaces";

const BreakdownSection = () => {
  const navigate = useNavigate();

  const {
    auth: {
      school_name,
      student_name,
      email,
      class_numerical,
      mobile_number,
      uuid,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  let questionCountByTopics = {
    Investing: 0,
    Banking: 0,
    "Financial planning": 0,
    "Tax planning": 0,
    "Loans & Credits": 0,
    Money: 0,
  };

  let ansCountByTopics = {
    Investing: 0,
    Banking: 0,
    "Financial planning": 0,
    "Tax planning": 0,
    "Loans & Credits": 0,
    Money: 0,
  };

  let initialTopicWiseBreakdown = {
    Money: {
      totalQuestion: 0,
      answeredRight: 0,
    },
    Banking: {
      totalQuestion: 0,
      answeredRight: 1,
    },
    "Financial planning": {
      totalQuestion: 0,
      answeredRight: 1,
    },
    Investing: {
      totalQuestion: 0,
      answeredRight: 0,
    },
    "Loans & Credits": {
      totalQuestion: 0,
      answeredRight: 0,
    },
    "Tax Planning": {
      totalQuestion: 0,
      answeredRight: 0,
    },
  };

  const searchStringIgnoreCase = (str: string, substr: string): boolean => {
    const lowerStr: string = str.toLowerCase();
    const lowerSubstr: string = substr.toLowerCase();
    return lowerStr.includes(lowerSubstr);
  };

  const [stateTopicWiseBreakdown, setStateTopicWiseBreakdown] =
    useState<ExamStatsTopicWise>(initialTopicWiseBreakdown);

  useEffect(() => {
    const fetchData = async () => {
      const samplePaperIDStr = localStorage.getItem("samplePaperID");
      if (samplePaperIDStr) {
        try {
          const response = await getTopicWiseExamResult(
            parseInt(samplePaperIDStr)
          );
          if (response.status === 200 && response.data && response.data.data) {
            console.log("Received Topicwise ExamResults. Setting State");
            const examResultBreakdown = response.data.data;
            setStateTopicWiseBreakdown(examResultBreakdown);
          }
        } catch (error) {
          console.error("Error fetching Topic wise Breakdown:", error);
        }
      } else {
        console.log(
          "No saved samplePaperID found. Using previous data, if available."
        );
      }
    };
    fetchData();
  }, []);

  enum TopicWiseRemarks {
    Excellent = "Excellent Performance",
    GoodPerf = "Good Performance",
    NeedsImprovment = "Needs Improvement",
  }

  return (
    <div className="BreakdownPageContainer">
      <div className="BreakdownBodyContainer">
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            src={NFO}
            style={{
              height: "100px",
              width: "auto",
              marginTop: 25,
              objectFit: "contain",
            }}
          />
        </div>

        <div
          style={{
            justifyContent: "center",
          }}
        >
          <NFOButtons
            type={NFOButtonTypes.GeneralButton}
            onButtonClick={() =>
              navigate("/complete", {
                state: {
                  backButtonTextStr: "Back",
                  backButtonNavigateStr: "/subscribedInfo",
                },
              })
            }
            buttonText="< Back to Results"
            buttonColor="#63656726"
            textColor="#2C6AB1"
            disableElevation={true}
            imagePostion="left"
            style={{
              justifyContent: "center",
            }}
            imageStyle={{
              width: "30px",
              marginRight: "0px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80vw",
            margin: "10px",
            position: "relative",
          }}
        >
          {stateTopicWiseBreakdown["Money"] &&
            stateTopicWiseBreakdown["Money"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={MoneyLogo}
                CategoryTitle="Money"
                countCorrectAns={stateTopicWiseBreakdown["Money"].answeredRight}
                totalQuestions={stateTopicWiseBreakdown["Money"].totalQuestion}
                performance={
                  stateTopicWiseBreakdown["Money"].answeredRight /
                    stateTopicWiseBreakdown["Money"].totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Money"].answeredRight /
                        stateTopicWiseBreakdown["Money"].totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
          {stateTopicWiseBreakdown["Financial Planning"] &&
            stateTopicWiseBreakdown["Financial Planning"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={FinancialPlanningLogo}
                CategoryTitle="Financial Planning"
                countCorrectAns={
                  stateTopicWiseBreakdown["Financial Planning"].answeredRight
                }
                totalQuestions={
                  stateTopicWiseBreakdown["Financial Planning"].totalQuestion
                }
                performance={
                  stateTopicWiseBreakdown["Financial Planning"].answeredRight /
                    stateTopicWiseBreakdown["Financial Planning"]
                      .totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Financial Planning"]
                        .answeredRight /
                        stateTopicWiseBreakdown["Financial Planning"]
                          .totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
          {stateTopicWiseBreakdown["Tax Planning"] &&
            stateTopicWiseBreakdown["Tax Planning"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={TaxPlanningLogo}
                CategoryTitle="Tax Planning"
                countCorrectAns={
                  stateTopicWiseBreakdown["Tax Planning"].answeredRight
                }
                totalQuestions={
                  stateTopicWiseBreakdown["Tax Planning"].totalQuestion
                }
                performance={
                  stateTopicWiseBreakdown["Tax Planning"].answeredRight /
                    stateTopicWiseBreakdown["Tax Planning"].totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Tax Planning"].answeredRight /
                        stateTopicWiseBreakdown["Tax Planning"].totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
          {stateTopicWiseBreakdown["Investments"] &&
            stateTopicWiseBreakdown["Investments"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={InvestmentLogo}
                CategoryTitle="Investments"
                countCorrectAns={
                  stateTopicWiseBreakdown["Investments"].answeredRight
                }
                totalQuestions={
                  stateTopicWiseBreakdown["Investments"].totalQuestion
                }
                performance={
                  stateTopicWiseBreakdown["Investments"].answeredRight /
                    stateTopicWiseBreakdown["Investments"].totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Investments"].answeredRight /
                        stateTopicWiseBreakdown["Investments"].totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
          {stateTopicWiseBreakdown["Banking"] &&
            stateTopicWiseBreakdown["Banking"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={BankingLogo}
                CategoryTitle="Banking"
                countCorrectAns={
                  stateTopicWiseBreakdown["Banking"].answeredRight
                }
                totalQuestions={
                  stateTopicWiseBreakdown["Banking"].totalQuestion
                }
                performance={
                  stateTopicWiseBreakdown["Banking"].answeredRight /
                    stateTopicWiseBreakdown["Banking"].totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Banking"].answeredRight /
                        stateTopicWiseBreakdown["Banking"].totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
          {stateTopicWiseBreakdown["Loans and Credit"] &&
            stateTopicWiseBreakdown["Loans and Credit"].totalQuestion > 0 && (
              <SectionalCards
                imageSrc={LoansAndCreditLogo}
                CategoryTitle="Loans and Credit"
                countCorrectAns={
                  stateTopicWiseBreakdown["Loans and Credit"].answeredRight
                }
                totalQuestions={
                  stateTopicWiseBreakdown["Loans and Credit"].totalQuestion
                }
                performance={
                  stateTopicWiseBreakdown["Loans and Credit"].answeredRight /
                    stateTopicWiseBreakdown["Loans and Credit"].totalQuestion >=
                  0.71
                    ? TopicWiseRemarks.Excellent
                    : stateTopicWiseBreakdown["Loans and Credit"]
                        .answeredRight /
                        stateTopicWiseBreakdown["Loans and Credit"]
                          .totalQuestion >=
                      0.41
                    ? TopicWiseRemarks.GoodPerf
                    : TopicWiseRemarks.NeedsImprovment
                }
              />
            )}
        </div>
      </div>
      <PageFooter footerText="All rights reserved | 2023" />
    </div>
  );
};

export default BreakdownSection;
