import React from 'react'
import { AlertIcon } from '../../../Assets/images';
import NFOButtons, { NFOButtonTypes } from '../../../Components/NFOButtons';

interface HandleUnavailableQpModalProps {
  cbCloseBlankQuizPage: () => void;
}

const HandleUnavailableQpModal: React.FC<HandleUnavailableQpModalProps> = ({
  cbCloseBlankQuizPage,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(0,0,0,0.9)",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: "10px",
          border: "none",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          maxWidth: "320px",
          maxHeight: "300px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "rgba(44, 106, 177, 1)",
            marginBottom: "10px",
            fontSize: "20px",
          }}
        >
          Attention
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <img
            src={AlertIcon}
            style={{
              height: "65px",
              width: "65px",
              marginBottom: "10px",
              marginTop: "10px",
              alignSelf: "center",
            }}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                paddingTop: "10px",
                paddingLeft: "10px",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              Sorry! Question Paper is unavailable. Please try another time.
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <NFOButtons
            onButtonClick={() => {
              cbCloseBlankQuizPage();
            }}
            type={NFOButtonTypes.GeneralButton}
            style={{ marginRight: "5px" }}
            buttonColor="#C53111"
            buttonText="End Exam"
            fontSize="13px"
            disableElevation={true}
          />
          {/* <button className="cont">Purchase</button> */}
        </div>
      </div>
    </div>
  )
}

export default HandleUnavailableQpModal;