import React from 'react'

const PageFooter = (props: {footerText?: string}) => {
  const footerText = props.footerText || "All rights reserved | 2023";
  return (
    <div className='footer-container'>
      <div className="page-footer">{footerText}</div>
    </div>
  )
}

export default PageFooter;