import React, { useRef, useState } from "react";
import { leftScroll, rightScroll } from "../Assets/images";

interface RenderMobButtonsProps {
  classname: string;
  stateCurrentQuestionIndex: number;
  stateQandOs: {
    question: string;
    options: string[];
    isFlagged: boolean;
    markedOptions: number[];
  }[];

  cbHandleButtonClick: (buttonNumber: number) => void;
}

const RenderMobileButtons = (props: RenderMobButtonsProps) => {
  const activeButtonStyle = {
    /*width: "40px",
    height: "40px",*/
    minWidth: "40px",
    minHeight: "40px",
    padding: "10px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(180, 219, 255, 1)",
  };

  const answeredButtonStyle = {
    /*width: "40px",
    height: "40px",*/
    minWidth: "40px",
    minHeight: "40px",
    padding: "10px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(139, 224, 230, 1)",
  };

  const inactiveButtonStyle = {
    /*width: "40px",
    height: "40px",*/
    minWidth: "40px",
    minHeight: "40px",
    padding: "10px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(228, 231, 234, 1)",
    background: "rgba(237,241,244,1)",
  };

  const flaggedButtonStyle = {
    /*width: "40px",
    height: "40px",*/
    minWidth: "40px",
    minHeight: "40px",
    padding: "10px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    //border: "2.582px solid rgba(230, 57, 70, 0.3)",
    //background: "rgba(230, 57, 70, 0.6)", red background
    border: "2.58px solid rgba(146, 155, 47, 0.5)", //yellowish border
    background: "rgba(205, 218, 60, 0.2)", //yellow background

  };

  const buttons = [];
  for (let i = 1; i <= props.stateQandOs.length; i++) {
    const buttonStyle =
      i === props.stateCurrentQuestionIndex + 1
        ? activeButtonStyle
        : props.stateQandOs[i - 1].isFlagged
        ? flaggedButtonStyle
        : props.stateQandOs[i - 1].markedOptions.length === 0
        ? inactiveButtonStyle
        : answeredButtonStyle;

    buttons.push(
      <button
        key={i}
        style={buttonStyle}
        onClick={() => props.cbHandleButtonClick(i)}
      >
        {i}
      </button>
    );
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollRight = () => {
    const container = buttonContainerRef.current;
    console.log("Container = ", container);
    if (container) {
      container.scrollLeft += 25; // Adjust as needed
      setScrollPosition(container.scrollLeft);
    }
  };

  const handleScrollLeft = () => {
    const container = buttonContainerRef.current;
    console.log("Container = ", container);
    if (container) {
      container.scrollLeft -= 25; // Adjust as needed
      setScrollPosition(container.scrollLeft);
    }
  };

  return (
    <div className={props.classname} ref={buttonContainerRef}>
      <div
        style={{
          display: "flex",
        }}
      >
        {scrollPosition > 0 && (
          <button
            style={{
              position: "absolute",
              left: 0,
              marginTop: 10,
              width: "40px",
              height: "40px",
              flexShrink: 0,
              borderRadius: "11px",
              margin: "4.5px",
              border: "2.582px solid rgba(83, 142, 195, 0)",
              background: "rgba(180, 219, 255, 0)",
            }}
            onClick={handleScrollLeft}
          >
            <img src={leftScroll} alt="<" />
          </button>
        )}
        {buttons.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
        {buttonContainerRef.current &&
          buttonContainerRef.current.scrollWidth &&
          scrollPosition + 1 <
            buttonContainerRef.current.scrollWidth -
              buttonContainerRef.current.clientWidth && (
            <button
              style={{
                position: "absolute",
                right: 0,
                marginTop: 10,
                width: "40px",
                height: "40px",
                flexShrink: 0,
                borderRadius: "11px",
                margin: "4.5px",
                border: "2.582px solid rgba(83, 142, 195, 0)",
                background: "rgba(180, 219, 255, 0)",
              }}
              onClick={handleScrollRight}
            >
              <img src={rightScroll} alt=">" />
            </button>
          )}
      </div>
    </div>
  );
};

export default RenderMobileButtons;
