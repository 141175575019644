import React, { useRef } from "react";
import { Button, ButtonBaseProps } from "@mui/material";

interface NFOGeneralButtonProps {
  buttonText: string;
  buttonColor?: string;
  textColor?: string;
  style? : React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  disableElevation?: boolean;
  fontSize?: string;
  buttonHeight?: string;
  setKeyInLocalStorageOnClick?: string;
  setValueInLocalStorageOnClick?: string;
  onButtonClick?: () => void;
}

const NFOGeneralButton: React.FC<NFOGeneralButtonProps> = ({
  buttonText,
  buttonColor,
  textColor,
  style,
  buttonStyle,
  disableElevation,
  fontSize,
  buttonHeight,
  setKeyInLocalStorageOnClick,
  setValueInLocalStorageOnClick,
  onButtonClick,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = () => {
    if(setKeyInLocalStorageOnClick && setValueInLocalStorageOnClick)
    {
      console.log("Setting key value in local storage");
      localStorage.setItem(setKeyInLocalStorageOnClick,setValueInLocalStorageOnClick);
    }
    if(onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        ...style
      }}
    >
      <Button
        onClick={handleClick}
        disableElevation = {disableElevation}
        style={{
          position: "relative",
          borderRadius: "51.512px",
          backgroundColor: buttonColor || "#2c6ab1",
          color: textColor || "white",
          fontSize: fontSize || 18,
          fontFamily: "Cardo",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(0,0,0,0.05)",
          boxSizing: "border-box",
          marginTop: "30px",
          height: buttonHeight || "40px",
          overflow: "hidden",
          flexShrink: 0,
          marginBottom: "15px",
          textTransform: "none",
          paddingLeft: buttonStyle?.paddingLeft || "20px",
          paddingTop: buttonStyle?.paddingTop || "10px",
          paddingRight: buttonStyle?.paddingRight || "20px",
          paddingBottom: buttonStyle?.paddingBottom || "10px",
          
          // padding: buttonStyle?.padding || "10px 20px 10px 20px",
          ...buttonStyle
        }}
        variant="contained"
      >
        {buttonText}
      </Button>
    </div>
  );
};

interface ImageButtonProps {
  buttonImageSrc?: string;
  buttonText: string;
  buttonColor?: string;
  textColor?: string;
  style? : React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  disableElevation?: boolean;
  imageStyle?: React.CSSProperties;
  imagePostion?: string;  //"left" or "right"
  fontSize?: string;
  buttonHeight?: string;
  setKeyInLocalStorageOnClick?: string;
  setValueInLocalStorageOnClick?: string;
  onButtonClick? : () => void;
}

const ImageButton: React.FC<ImageButtonProps> = ({
  buttonText,
  buttonColor,
  buttonImageSrc,
  textColor,
  style,
  buttonStyle,
  disableElevation,
  imageStyle,
  imagePostion,
  fontSize,
  buttonHeight,
  setKeyInLocalStorageOnClick,
  setValueInLocalStorageOnClick,
  onButtonClick,
}) => {
  const handleClick = () => {
    if(setKeyInLocalStorageOnClick && setValueInLocalStorageOnClick)
    {
      console.log("Setting key value in local storage");
      localStorage.setItem(setKeyInLocalStorageOnClick,setValueInLocalStorageOnClick);
    }
    if(onButtonClick) {
      onButtonClick();
    }
  };
  return (
    <div style={{
          display: "flex",
         ...style
        }}
    >
      <Button
        onClick={handleClick}
        disableElevation = {disableElevation}
        style={{
          position: "relative",
          borderRadius: "51.512px",
          backgroundColor: buttonColor || " #2c6ab1",
          color: textColor || "white",
          fontSize: fontSize || 18,
          fontFamily: "Cardo",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(0,0,0,0.05)",
          boxSizing: "border-box",
          marginTop: "30px",
          height: buttonHeight || "40px",
          overflow: "hidden",
          flexShrink: 0,
          marginBottom: "15px",
          textTransform: "none",

          paddingLeft: buttonStyle?.paddingLeft || "20px",
          paddingTop: buttonStyle?.paddingTop || "10px",
          paddingRight: buttonStyle?.paddingRight || "20px",
          paddingBottom: buttonStyle?.paddingBottom || "10px",
          // padding: "10px 20px 10px 20px",
          ...buttonStyle
        }}
        variant="contained"
      >
        {(imagePostion === "left" || false) && 
          <img
            src={buttonImageSrc}
            alt=""
            style={{ 
              width: imageStyle?.width || "20px", 
              marginRight: imageStyle?.marginRight || "10px",
              ...imageStyle
            }}
          />
        }
        {buttonText}
        {(imagePostion !== "left") && 
          <img
            src={buttonImageSrc}
            alt=""
            style={{ 
              width: imageStyle?.width || "20px", 
              marginRight: imageStyle?.marginRight || "10px",
              ...imageStyle
            }}
          />
        }
      </Button>
    </div>
  );
};

/**
 * This is the enum types for buttons
 *  GeneralButton
 *  ImageButton
 */
export enum NFOButtonTypes {
  GeneralButton = "GeneralButton",
  ImageButton = "ImageButton",
}

interface NFOButtonProps {
  type: NFOButtonTypes;
  buttonText: string;
  justifyContent?: string;
  buttonColor?: string;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  disableElevation?: boolean;
  buttonImageSrc?: string;
  imageStyle?: React.CSSProperties;
  textColor?: string;
  imagePostion?: string;
  fontSize?: string;
  buttonHeight?: string;
  setKeyInLocalStorageOnClick?: string;
  setValueInLocalStorageOnClick?: string;
  onButtonClick?: () => void;
}

const NFOButtons: React.FC<NFOButtonProps> = ({ 
  type,
  buttonText,
  style,
  buttonColor,
  buttonStyle,
  disableElevation,
  buttonImageSrc,
  imageStyle,
  textColor,
  imagePostion,
  fontSize,
  buttonHeight,
  setKeyInLocalStorageOnClick,
  setValueInLocalStorageOnClick,
  onButtonClick,
}) => {
  switch (type) {
    case (type = NFOButtonTypes.GeneralButton):
      return <NFOGeneralButton 
        buttonText={buttonText}
        style={style} 
        buttonColor={buttonColor} 
        buttonStyle={buttonStyle} 
        disableElevation={disableElevation}
        textColor={textColor}
        fontSize={fontSize}
        buttonHeight={buttonHeight}
        setKeyInLocalStorageOnClick={setKeyInLocalStorageOnClick}
        setValueInLocalStorageOnClick={setValueInLocalStorageOnClick}
        onButtonClick={onButtonClick}
      />;
    case (type = NFOButtonTypes.ImageButton):
      return <ImageButton 
        buttonText={buttonText} 
        style={style} 
        buttonColor={buttonColor} 
        buttonStyle={buttonStyle} 
        disableElevation={disableElevation}
        buttonImageSrc={buttonImageSrc}
        imageStyle={imageStyle}
        textColor={textColor}
        imagePostion={imagePostion}
        fontSize={fontSize}
        buttonHeight={buttonHeight}
        setKeyInLocalStorageOnClick={setKeyInLocalStorageOnClick}
        setValueInLocalStorageOnClick={setValueInLocalStorageOnClick}
        onButtonClick={onButtonClick}
      />;
  }
};

export default NFOButtons;
