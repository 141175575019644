import Cookies from "js-cookie";
import "./InvalidSession.css";
import { createNewSession } from "../../App";
import { NFO, background } from "../../Assets/images";

const InvalidSession = ({ switchToLogin }: { switchToLogin: () => void }) => {
  const logOutAllDevices = async () => {
    // const authUuid = Cookies.get("x_id");
    // if (authUuid) {
    //   await createNewSession(authUuid);
    // }
    window.location.reload();
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return (
    <div>
      <img
        src={background}
        style={{
          zIndex: 0,
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      />
      <div
        style={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img className="logo-icon-invalid" alt="" src={NFO} />
        <p
          style={{
            color: "rgba(232,99,23,1)",
            textAlign: "center",
            fontFamily: "Cardo",
            margin: 40,
            fontSize: 25,
          }}
        >
          You have logged in using multiple devices. <br />
          Please logout of all devices to continue.
        </p>
        <div
          className="log-all-out"
          style={{
            backgroundColor: "rgba(165, 77, 29, 1)",
            borderRadius: 100,
            color: "white",
            marginTop: 20,
            padding: 15,
            fontSize: 18,
            paddingLeft: 20,
            paddingRight: 20,
          }}
          onClick={() => {
            // logOutAllDevices();
            localStorage.clear();
            sessionStorage.clear();
            openInSameTab(
              // "https://feature-install-sentry.dju6egfwk6tjj.amplifyapp.com/"
              "https://nfoportal.nationalfinanceolympiad.com/"
            );
          }}
        >
          Logout of all devices and login here
          <br />
        </div>
        <div
          className="log-all-out"
          style={{
            color: "rgba(44, 106, 177, 1)",
            marginTop: 20,
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          Back to login
        </div>
      </div>
    </div>
  );
};

export default InvalidSession;
