import axios from "axios";
import { getCookieValue } from "../helpers";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: "https://onboarding.streakcard.click/nfo", // Replace with your API base URL
});

// Axios request interceptor for adding the access token to requests
api.interceptors.request.use(
  (config) => {
    const tokenValue = localStorage.getItem("accessToken");
    if (tokenValue) {
      config.headers.Authorization = tokenValue;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor to refresh the access token if it's expired
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      console.error("Failed to refresh access token");
      // Example: logoutUser();
      throw new Error("Failed to refresh access token");
    }

    return Promise.reject(error);
  }
);

export default api;
