import React, { useContext, useRef, useState } from "react";
import { ExamResult } from "../../../Utils/DefinitionsInterfaces";
import { AuthContext } from "../../../App";
import { leftScroll, rightScroll } from "../../../Assets/images";

interface RenderButtonsProps {
  classname: string;
  stateCurrentSolutionIndex: number;
  cbHandleButtonClick: (buttonNumber: number) => void;
}

const RenderButtonsForAnswerSwitch = (props: RenderButtonsProps) => {
  const {
    auth: {
      school_name,
      student_name,
      email,
      class_numerical,
      mobile_number,
      uuid,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const activeButtonStyle = {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(180, 219, 255, 1)",
  };

  const correctAnsButtonStyle = {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(139, 224, 230, 1)",
  };

  const unansweredButtonStyle = {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(228, 231, 234, 1)",
    background: "rgba(237,241,244,1)",
  };

  const wrongAnsButtonStyle = {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(255, 193, 180, 1)",
  };

  const buttons = [];

  for (let i = 1; i <= stateQuizAnalysis.totalQuestion; i++) {
    const buttonStyle =
      i === props.stateCurrentSolutionIndex + 1
        ? activeButtonStyle
        : stateQuizAnalysis?.questions[i - 1]?.answeredCorrect
        ? correctAnsButtonStyle
        : stateQuizAnalysis?.questions[i - 1]?.answerSubmitted.length === 0
        ? unansweredButtonStyle
        : wrongAnsButtonStyle;

    buttons.push(
      <button
        key={i}
        style={buttonStyle}
        onClick={() => props.cbHandleButtonClick(i)}
      >
        {i}
      </button>
    );
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollRight = () => {
    const container = buttonContainerRef.current;
    console.log("Container = ", container);
    if (container) {
      container.scrollLeft += 100; // Adjust as needed
      setScrollPosition(container.scrollLeft);
    }
  };

  const handleScrollLeft = () => {
    const container = buttonContainerRef.current;
    console.log("Container = ", container);
    if (container) {
      container.scrollLeft -= 100; // Adjust as needed
      setScrollPosition(container.scrollLeft);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifySelf: "center",
        justifyContent: "left",
        width: "100%",
      }}
    >
      {
        /* {scrollPosition > 0 && */ <button
          style={{
            position: "relative",
            left: 0,
            marginTop: 10,
            width: "40px",
            height: "40px",
            flexShrink: 0,
            borderRadius: "11px",
            margin: "4.5px",
            border: "2.582px solid rgba(83, 142, 195, 0)",
            //background: "rgba(180, 219, 255, 1)",
          }}
          onClick={handleScrollLeft}
        >
          <img src={leftScroll} alt="<" />
        </button>
      }
      <div
        ref={buttonContainerRef}
        style={{
          overflowX: "hidden",
          marginLeft: "18px",
          marginRight: "18px",
        }}
      >
        <div
          style={{
            display: "flex",
            // width: "90vw",
          }}
        >
          {buttons.map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
        </div>
      </div>
      {
        /* {buttonContainerRef.current &&
      buttonContainerRef.current.scrollWidth &&
      scrollPosition + 1 <
        buttonContainerRef.current.scrollWidth -
          buttonContainerRef.current.clientWidth && */ <button
          style={{
            position: "relative",
            right: 0,
            marginTop: 10,
            width: "40px",
            height: "40px",
            flexShrink: 0,
            borderRadius: "11px",
            margin: "4.5px",
            border: "2.582px solid rgba(83, 142, 195, 0)",
            //background: "rgba(180, 219, 255, 1)",
          }}
          onClick={handleScrollRight}
        >
          <img src={rightScroll} alt=">" />
        </button>
      }
    </div>
  );
};

export default RenderButtonsForAnswerSwitch;
