import React from 'react';
import "./SectionalCards.css";

interface SectionalCardsProps {
  imageSrc: string,
  CategoryTitle: string,
  countCorrectAns: number,
  totalQuestions: number,
  performance: string,
}

const SectionalCards = (props: SectionalCardsProps) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        //position: "absolute",
        //top: 0,
        flexDirection: "column",
        bottom: 0,
        left: 0,
        right: 0,
        width: 285,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          border: "3px solid rgba(0, 0, 0, 0.10)",
          paddingTop: 30,
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 30,
          marginTop: 20,
        }}
      >
        <div className='image-container'>
          <img
            alt=""
            src={props.imageSrc}
            style={{
              height: "142px",
              // width: "auto",
              //maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            color: "#2C6AB1",
            textAlign: "center",
            fontFamily: "Cardo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "75%",//"137.4%" /* 27.48px */,
          }}
        >
          <br /> {props.CategoryTitle} <br />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "50%",
            /*gap: 10*/
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // width: "100%",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <hr
                style={{
                  position: "relative",
                  left: 0,
                  //top: 8,
                  width: "160px",
                  marginBottom: 0,
                }}
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <hr
                style={{
                  position: "relative",
                  right: 0,
                  //top: 8,
                  width: "60px",
                  marginBottom: 0,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            color: (props.performance === "Need Improvement") ? "#E26D6D"
                  : (props.performance === "Excellant Performance") ? "green"
                  : "orange",
            textAlign: "center",
            fontFamily: "Cardo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "75%",//"137.4%" /* 27.48px */,
          }}
        >
          <br /> {props.performance} <br />
        </div>
        <div
          style={{
            color: "#2C6AB1",
            textAlign: "center",
            fontFamily: "Cardo",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "75%",//"137.4%" /* 27.48px */,
            marginTop: 10,
          }}
        >
          <br /> {props.countCorrectAns} / {props.totalQuestions} &nbsp; answered right <br />
        </div>
      </div>
    </div>
  )
}

export default SectionalCards;