import React from "react";
import NFOModals, { ModalTypes } from "../../../Components/NFOModals";
import { AlertIcon } from "../../../Assets/images";
import NFOButtons, { NFOButtonTypes } from "../../../Components/NFOButtons";

interface WarningModalProps {
  warningText: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  isVisible: boolean;
  headingText?: string;
}

const WarningModal: React.FC<WarningModalProps> = ({
  warningText,
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  isVisible,
  headingText,
}) => {
  return (
    <NFOModals
      modalType={ModalTypes.GeneralModal}
      isVisible={isVisible}
      onClose={() => {
        onClickPrimaryButton();
      }}
    >
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: 15,
            padding: "10px",
            border: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",

            flexDirection: "column",
            alignSelf: "center",
            maxWidth: "320px",
            alignItems: "center",

            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "rgba(44, 106, 177, 1)",
              marginBottom: "10px",
              fontSize: "20px",
              marginTop: "10px",
            }}
          >
            {headingText || "Attention"}
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={AlertIcon}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            />

            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                padding: "10px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {warningText}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            {secondaryButtonText && (
              <NFOButtons
                onButtonClick={() => {
                  onClickSecondaryButton();
                }}
                type={NFOButtonTypes.GeneralButton}
                style={{ marginRight: "5px" }}
                buttonColor="#C53111"
                buttonText={secondaryButtonText}
                fontSize="13px"
                disableElevation={true}
              />
            )}

            {primaryButtonText && (
              <NFOButtons
                onButtonClick={() => {
                  onClickPrimaryButton();
                }}
                type={NFOButtonTypes.GeneralButton}
                buttonText={primaryButtonText}
                disableElevation={true}
                style={{ marginLeft: "5px" }}
                fontSize="13px"
                buttonStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
              />
            )}
          </div>
        </div>
      </div>
    </NFOModals>
  );
};

export default WarningModal;
