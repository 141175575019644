import React, { useRef, useState } from "react";
import { Ellipse, NFO, RightArrowSVG } from "../../../Assets/images";
import { useNavigate } from "react-router";
import "./MobileSummaryCard.css";
import NFOButtons, { NFOButtonTypes } from "../../../Components/NFOButtons";
import WarningModal from "../../Info/Modals/WarningModal";

interface MobileSummaryCardProps {
  examsLeft: number;
  overallPercentage: number;
  totalCorrectAnswerGiven: number;
  questionsAnswered: number;
  timeTaken: string;
  totalQuestion: number;
  cbHandleTakeAnotherTest: () => void;
}

const MobileSummaryCard: React.FC<MobileSummaryCardProps> = ({
  examsLeft,
  totalCorrectAnswerGiven,
  overallPercentage,
  questionsAnswered,
  timeTaken,
  totalQuestion,
  cbHandleTakeAnotherTest,
}) => {
  const navigate = useNavigate();

  const showTopicwiseBreakdown = () => {
    navigate("/breakdown-section");
  };
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const handleFullscreen = () => {
    const element = fullScreenRef.current;

    if (element && element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          console.log("Fullscreen request succeeded");
        })
        .catch((error) => {
          console.error("Fullscreen request failed:", error);
        });
    }
  };

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const handleCloseModal = (type: "rulesModal" | "warningModal") => {
    // if (type == "rulesModal") {
    //   setIsRulesModalOpen(false);
    // } else
    if (type == "warningModal") {
      setIsWarningModalOpen(false);
    }
  };

  const handleOpenModal = (type: "rulesModal" | "warningModal") => {
    // if (type == "rulesModal") {
    //   setIsRulesModalOpen(true);
    // } else
    if (type == "warningModal") {
      setIsWarningModalOpen(true);
    }
  };

  return (
    <div className="mobile-summary-card" ref={fullScreenRef}>
      <div className="parentContainer">
        <div className="mobileWhiteCard">
          <div className="score">
            <div className="scored">{totalCorrectAnswerGiven}</div>
            <div className="out-of"> out of {totalQuestion}</div>
          </div>
          <div
            style={{
              fontSize: "30px",
              color: "rgba(44, 106, 177, 1)",
            }}
          >
            Exam Completed
            <div
              style={{
                fontSize: "14px",
                color: "rgba(44, 106, 177, 1)",
              }}
            >
              {examsLeft === 0 && <>No</>}
              {examsLeft > 0 && examsLeft}
              &nbsp; exam{examsLeft > 1 && <>s</>} left
            </div>
          </div>
          <NFOButtons
            buttonText="Take another Mock Olympiad"
            disableElevation
            fontSize="13px"
            buttonStyle={{ marginTop: "-5px", borderWidth: 0 }}
            type={NFOButtonTypes.GeneralButton}
            onButtonClick={cbHandleTakeAnotherTest}
          />

          <NFOButtons
            buttonText="Retry this Mock-Olympiad"
            disableElevation
            fontSize="13px"
            buttonStyle={{
              marginTop: "-5px",
              borderWidth: 0,
            }}
            buttonColor="#CCE4FF"
            textColor="rgba(44, 106, 177, 1)"
            type={NFOButtonTypes.GeneralButton}
            onButtonClick={() => {
              handleOpenModal("warningModal");
            }}
          />

          <div className="mobileTime">
            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                textAlign: "left",
              }}
            >
              Overall Percentage :{" "}
              {overallPercentage.toFixed(2).replace(/\.00$/, "")}%
            </div>
            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                textAlign: "left",
              }}
            >
              Questions answered: {questionsAnswered}
            </div>
            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                display: "flex",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              Time :&nbsp;{timeTaken}
            </div>
          </div>

          <button
            style={{
              backgroundColor: "rgba(235, 236, 237, 0.3)",
              borderWidth: 1.5,
              borderColor: "rgba(0, 0, 0, 0.05)",
              display: "flex",
              justifyContent: "space-between",
              width: "80vw",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              alignItems: "center",
              borderRadius: 10,
            }}
            onClick={showTopicwiseBreakdown}
          >
            <div style={{ color: "rgba(44, 106, 177, 1)", fontSize: "20px" }}>
              Topicwise Breakdown
            </div>
            <div
              style={{
                display: "flex",
                color: "rgba(44, 106, 177, 0.5)",
                fontSize: "20px",
                fontWeight: "lighter",
                alignItems: "center",
              }}
            >
              <img
                src={RightArrowSVG}
                style={{ height: "12px", width: "12px" }}
              />
            </div>
          </button>
        </div>
      </div>
      <WarningModal
        onPress={() => {
          // handleFullscreen();
          navigate("/quiz");
        }}
        isVisible={isWarningModalOpen}
        onClose={(value: boolean) => {
          handleCloseModal("warningModal");
        }}
      />
    </div>
  );
};

export default MobileSummaryCard;
