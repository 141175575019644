import React from "react";
import { NFO } from "../../../Assets/images";

const CompletePageHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        alt=""
        src={NFO}
        style={{
          height: "100px",
          width: "auto",
          marginTop: 20,
          objectFit: "contain",
        }}
      />
    </div>
  );
};

export default CompletePageHeader;
