import React, { useContext, useRef, useState } from "react";
import { ExamData, ExamResult } from "../../../Utils/DefinitionsInterfaces";
import { AuthContext } from "../../../App";
import NFOButtons, { NFOButtonTypes } from "../../../Components/NFOButtons";
import { useNavigate } from "react-router";
import { whiteRightArrow } from "../../../Assets/images";

interface RenderPrevScoresProps {
  stateAllQPs: ExamData[];
}

const RenderPreviousScoreCards = (props: RenderPrevScoresProps) => {
  const navigate = useNavigate();

  const {
    auth: {
      school_name,
      student_name,
      email,
      class_numerical,
      mobile_number,
      uuid,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const activeCardStyle = {
    width: "40px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(180, 219, 255, 1)",
  };

  const inactiveCardStyle = {
    borderRadius: "11px",
    margin: "4.5px",
    border: "2.582px solid rgba(83, 142, 195, 0.50)",
    background: "rgba(180, 219, 255, 0.10)",
    color: "red",
  };

  const handleGetExamAnalysis = () => {
    navigate("/complete", {
      state: {
        backButtonTextStr: "Back",
        backButtonNavigateStr: "/subscribedInfo",
      },
    });
  };

  const extractDate = (dateString: string | null): string => {
    let finalDate = "";
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear().toString();
      finalDate = `${day}/${month}/${year}`;
    }
    return finalDate;
  };

  const dateFormat: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // const prevQPsCards = [];
  let keyCount = 0;
  const prevExamCounting = [
    "",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  const parseUTCToDate = (utcTimestamp: any) => {
    return utcTimestamp ? new Date(utcTimestamp) : null;
  };
  // Helper function to convert time_spent to total seconds
  const timeSpentToSeconds = (time_spent: any) => {
    if (!time_spent) return null;
    const [minutes, seconds] = time_spent.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  // Sort the array based on dateOnWhichExamTaken in ascending order
  const sortedQPs = props.stateAllQPs.sort((a, b) => {
    const dateA = parseUTCToDate(a.dateOnWhichExamTaken);
    const dateB = parseUTCToDate(b.dateOnWhichExamTaken);

    if (!dateA && !dateB) {
      return 0;
    } else if (!dateA) {
      return 1;
    } else if (!dateB) {
      return -1;
    } else {
      return dateA.getTime() - dateB.getTime();
    }
  });

  const prevQPsCards = sortedQPs
    .map((qp, index) => {
      if (qp.examTaken === true) {
        keyCount++;
        console.log("sampleID during saving = ", qp.samplePaperID.toString());

        return (
          <div style={{ color: "#2C6AB1" }} key={index}>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <hr
                  className="custom-hr"
                  style={{
                    position: "relative",
                    left: 0,
                    top: 0,
                    width: "383px",
                    maxWidth: "90vw",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  fontSize: 15,
                }}
              >
                {keyCount <= 10 && (
                  <div>
                    {prevExamCounting[keyCount]} Exam:&nbsp;&nbsp;
                    {qp.percentageObtained?.toFixed(2)}%
                  </div>
                )}
                {keyCount > 10 && (
                  <div>
                    Next Exam:&nbsp;&nbsp;
                    {qp.percentageObtained?.toFixed(2)}%
                  </div>
                )}
                <div style={{ opacity: "0.7" }}>
                  {qp.dateOnWhichExamTaken
                    ? new Date(qp.dateOnWhichExamTaken).toLocaleString(
                        "en-US",
                        dateFormat
                      )
                    : "Date not available"}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <NFOButtons
                  type={NFOButtonTypes.ImageButton}
                  buttonText="View Details/Retry"
                  imagePostion="right"
                  imageStyle={{
                    height: "8px",
                    width: "8px",
                    margin: 0,
                    marginLeft: 2,
                  }}
                  buttonImageSrc={whiteRightArrow}
                  setKeyInLocalStorageOnClick="samplePaperID"
                  setValueInLocalStorageOnClick={qp.samplePaperID.toString()}
                  onButtonClick={handleGetExamAnalysis}
                  disableElevation={true}
                  buttonStyle={{
                    width: "125px",
                    height: "30px",
                    color: "white",
                    fontSize: 11,
                    overflow: "visible",
                    padding: 0,
                    margin: 0,
                    lineHeight: 0,
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
      return null;
    })
    .filter((item) => item !== null);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {prevQPsCards.map((card, index) => (
          <React.Fragment key={index}>{card}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RenderPreviousScoreCards;
