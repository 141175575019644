import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import "./subscribedInfo.css";
import {
  NFO,
  NFOLogo2x,
  Ellipse,
  QPs3_Olympiad,
  PhoneIcon,
  // alert,
  x_circle,
  questionMark,
  questionMark2,
  QPs3_afterFreeMock,
} from "../../Assets/images";

import NFOButtons, { NFOButtonTypes } from "../../Components/NFOButtons";
import PageFooter from "../../Components/PageFooter";
import NFOModals, { ModalTypes } from "../../Components/NFOModals";
import WarningModal from "../Info/Modals/WarningModal";
import RulesModal from "../Info/Modals/RulesModal";
import {
  fetchSamplePaperDetails,
  fetchSamplePapers,
  getOrderStatus,
  getPaymentLinkForOrder,
  requestUnlockOlympiad,
  subscribeForNewMock,
} from "../../Utils/MockQuizAPIs/MockQuizAPIs";
import { AuthContext } from "../../App";
import RenderPreviousScoreCards from "./Components/RenderPreviousScoreCards";
import {
  ApiResponseGetPaymentLink,
  ExamData,
} from "../../Utils/DefinitionsInterfaces";
import NotifyForUpcomingModal from "./Components/Modals/NotifyForUpcomingModal";
import PurchaseModal from "../Complete/components/Modals/PurchaseModal";
import { merchantLogoString, quizItemId } from "../../Utils/constants";
import { AxiosResponse } from "axios";

interface User {
  uuid: string;
  school_name: string;
  student_name: string;
  mobile_number: string;
  email: string;
  class: string;
  class_numerical: number;
}

const ITEM_ID = 2;

const SubscribedInfo = () => {
  const navigate = useNavigate();

  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const [allQPs, setAllQPs] = useState<ExamData[]>([]);
  const [numExamsLeft, setNumExamsLeft] = useState(0);
  const [numExamsTaken, setNumExamsTaken] = useState(0);

  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("order_id");
  const accessToken = urlParams.get("token");
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  }

  const paymentCallbackFunction = async () => {
    if (orderId) {
      console.log("Order id =", orderId);
      const orderStatus = await verifyPayment(orderId);
      console.log("order status = ", orderStatus);
      if (orderStatus) {
        handlePurchaseTests();
      } else {
        alert("Payment Failed");
        navigate("/subscribedInfo");
      }
    }
  };

  useEffect(() => {
    const noNeedToCheckParams = localStorage.getItem("noNeedToCheckParams");
    if (noNeedToCheckParams && noNeedToCheckParams === "true") {
      localStorage.removeItem("noNeedToCheckParams");
      navigate("/subscribedInfo");
    } else {
      paymentCallbackFunction();
    }
    const fetchData = async () => {
      try {
        const response = await fetchSamplePapers();
        if (response.status === 200 && response.data && response.data.data) {
          const QPArray = response.data.data;
          setAllQPs(QPArray);
          let examsTaken = 0;
          for (let i = 0; i < QPArray.length; i++) {
            if (QPArray[i].examTaken) {
              examsTaken++;
            }
          }
          setNumExamsLeft(QPArray.length - examsTaken);

          console.log({ numExamsLeft });
          setNumExamsTaken(examsTaken);
          if (0 === examsTaken) {
            navigate("/info");
          }
        }
      } catch (error) {
        console.error("Error fetching Sample Papers Metadata:", error);
      }
    };
    fetchData();
  }, []);

  //Placeholder function to make the payment and then return
  const purchaseTests = (studentId: string): boolean => {
    return true;
  };

  const handlePurchaseTests = async (): Promise<void> => {
    const areMocksPurchased = purchaseTests(student_username);
    if (areMocksPurchased) {
      try {
        const responseUnlockOlympiad = await requestUnlockOlympiad(
          student_username
        );
        if (responseUnlockOlympiad.status === 200) {
          // handleClosePurchaseModal();
          localStorage.setItem("noNeedToCheckParams", "true");
          window.location.reload();
          // window.location.href =
          //   window.location.origin + window.location.pathname;
        } else {
          throw "Error in Unlock Olympiad Response";
        }
      } catch (error) {
        console.log("Error unlocking Mock olympiad. Error : ", error);
        // handleClosePurchaseModal();
      }
    } else {
      console.log("Payment not done. Purchase failed");
      // handleClosePurchaseModal();
    }
  };

  const handleOpenNotifyModal = () => {
    setIsNotifyModalOpen(true);
  };

  const handleCloseNotifyModal = () => {
    setIsNotifyModalOpen(false);
  };

  const handleCloseModal = (type: "rulesModal" | "warningModal") => {
    if (type == "rulesModal") {
      setIsRulesModalOpen(false);
    } else if (type == "warningModal") {
      setIsWarningModalOpen(false);
    }
  };

  const handleOpenModal = (type: "rulesModal" | "warningModal") => {
    if (type == "rulesModal") {
      setIsRulesModalOpen(true);
    } else if (type == "warningModal") {
      setIsWarningModalOpen(true);
    }
  };

  const handleClosePurchaseModal = () => {
    setIsPurchaseModalOpen(false);
  };

  const handleOpenPurchaseModal = () => {
    setIsPurchaseModalOpen(true);
  };

  const handleSubscribeForUpcoming = async (): Promise<void> => {
    try {
      const response = await subscribeForNewMock();
      if (response.status === 200) {
        console.log("Subscribed");
      } else {
        console.log("Not subscribed");
      }
    } catch (error) {
      console.error("Error Subscribing to upcoming. Error :", error);
    }
    handleCloseNotifyModal();
  };

  const fullScreenRef = useRef<HTMLDivElement>(null);
  const handleFullscreen = () => {
    const element = fullScreenRef.current;

    if (element && element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          console.log("Fullscreen request succeeded");
        })
        .catch((error) => {
          console.error("Fullscreen request failed:", error);
        });
    }
  };

  const handleFindUnansweredQuiz = async (): Promise<void> => {
    console.log("Handle Find Unused Mock and then Begin Quiz function");
    let samplePaperID = 0,
      foundPaper = false,
      hasStudentPaid = false;
    for (let i = 0; !foundPaper && i < allQPs.length; i++) {
      hasStudentPaid =
        i === 0
          ? allQPs[i].paidForMockOlympiad
          : hasStudentPaid && allQPs[i].paidForMockOlympiad;

      if (
        allQPs[i].examTaken === false &&
        false === allQPs[i].paid &&
        allQPs[i].samplePaperID !== 10
      ) {
        //Means Exam not taken and exam is free
        samplePaperID = allQPs[i].samplePaperID;
        foundPaper = true;
      } else if (
        false === allQPs[i].examTaken &&
        true === allQPs[i].paid &&
        true === allQPs[i].paidForMockOlympiad &&
        allQPs[i].samplePaperID !== 10
      ) {
        //Means this paid student has not taken this paid Exam yet
        samplePaperID = allQPs[i].samplePaperID;
        foundPaper = true;
      }
    }
    if (foundPaper) {
      handleOpenModal("warningModal");
    } else if (hasStudentPaid) {
      console.log("No Paper left for student");
      handleOpenNotifyModal();
    } else {
      // student needs to purchase
      console.log("Student Needs to purchase");
      handleOpenPurchaseModal();
    }
  };

  const handleBeginMockQuiz = async (): Promise<void> => {
    console.log("Handle Find Unused Mock and then Begin Quiz function");
    let samplePaperID = 0,
      foundPaper = false,
      hasStudentPaid = false;
    for (let i = 0; !foundPaper && i < allQPs.length; i++) {
      hasStudentPaid =
        i === 0
          ? allQPs[i].paidForMockOlympiad
          : hasStudentPaid && allQPs[i].paidForMockOlympiad;

      if (
        allQPs[i].examTaken === false &&
        false === allQPs[i].paid &&
        allQPs[i].samplePaperID !== 10
      ) {
        //Means Exam not taken and exam is free
        samplePaperID = allQPs[i].samplePaperID;
        foundPaper = true;
      } else if (
        false === allQPs[i].examTaken &&
        true === allQPs[i].paid &&
        true === allQPs[i].paidForMockOlympiad &&
        allQPs[i].samplePaperID !== 10
      ) {
        //Means this paid student has not taken this paid Exam yet
        samplePaperID = allQPs[i].samplePaperID;
        foundPaper = true;
      }
    }
    if (foundPaper) {
      localStorage.setItem("samplePaperID", samplePaperID.toString());
      console.log("Set in localStorage : samplePaperID = ", samplePaperID);
      // handleFullscreen();
      navigate("/quiz");
    } else if (hasStudentPaid) {
      console.log("Code Should not reach here");
      // handleCloseModal("warningModal");
      // handleOpenNotifyModal();
    } else {
      // student needs to purchase
      console.log("Code should not reach here");
      // handleCloseModal("warningModal");
      // handleOpenPurchaseModal();
    }
  };

  const getSessionIdBD = async (): Promise<
    AxiosResponse<ApiResponseGetPaymentLink>
  > => {
    let orderNote = quizItemId; //"51"
    // orderNote = "27,28";
    const RequestData = {
      orderNote: orderNote,
    };
    let responseGetLinks = await getPaymentLinkForOrder(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };
  const handleCheckoutNavigate = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      openInSameTab(
        `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${ITEM_ID}&token=${token}&userName=${student_username}`
      );
    } else {
      console.log("Access token not found in localstorage");
    }
  };

  const handlePaymentClick = async () => {
    handleClosePurchaseModal();
    try {
      const response = await getSessionIdBD();
      //@ts-ignore
      console.log(response.data.data.links[1].parameters.bdorderid);
      //@ts-ignore
      if (response.data.status == "Success") {
        var flow_config = {
          //@ts-ignore
          merchantId: response.data.data.links[1].parameters.mercid,
          //@ts-ignore
          bdOrderId: response.data.data.links[1].parameters.bdorderid,
          //@ts-ignore
          authToken: response.data.data.links[1].headers.authorization,
          childWindow: true,
          //@ts-ignore
          returnUrl: `https://olympiad.nationalfinanceolympiad.com/subscribedInfo/?order_id=${response.data.data.orderID}`,
          retryCount: 3,
          prefs: {
            payment_categories: ["card", "nb"],
            allowed_bins: ["540000", "400000"],
          },
          netBanking: {
            showPopularBanks: "Y",
            popularBanks: ["Kotak Bank", " AXIS Bank [Retail]"],
          },
        };

        var responseHandler = function (txn: {
          status: string;
          response: string;
        }) {
          console.log("callback received status:: ", txn.status);
          console.log("callback received response:: ", txn.response);
        };

        var theme_config = {
          sdkPrimaryColor: "#69068a",
          sdkAccentColor: "#cf5df5",
          sdkBackgroundColor: "#f2caff",
          sdkBannerColor: "#982cbb",
        };

        var config = {
          responseHandler: responseHandler,
          merchantLogo: merchantLogoString,
          flowConfig: flow_config,
          flowType: "payments",
          themeConfig: theme_config,
        };
        //@ts-ignore
        if (window.loadBillDeskSdk) {
          // Assuming you have a config object

          //@ts-ignore
          window.loadBillDeskSdk(config);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyPayment = async (orderId: string) => {
    try {
      const response = await getOrderStatus(orderId);
      if (response) {
        //@ts-ignore
        if (response.data.data.paymentStatus === "success") {
          console.log("Payment Successful");
          return true;
          //@ts-ignore
        } else if (response.data.data.paymentStatus === "retry") {
          console.log("Retry payment");
          return false;
          //@ts-ignore
        } else if (
          //@ts-ignore
          response.data.data.paymentStatus === "payment_processing_error"
        ) {
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <div className="containerParent">
      <div ref={fullScreenRef} className="subsQuizInfo">
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            src={NFO}
            style={{
              height: "100px",
              width: "auto",
              marginTop: 100,
              objectFit: "contain",
            }}
          />
        </div>

        <div
          style={{
            justifyContent: "center",
          }}
        >
          <NFOButtons
            type={NFOButtonTypes.ImageButton}
            onButtonClick={() =>
              window.open(
                "https://nfoportal.nationalfinanceolympiad.com/",
                "_self"
              )
            }
            buttonText="Hub Home"
            buttonColor="#63656715"
            textColor="#2C6AB1"
            disableElevation={true}
            buttonImageSrc={x_circle}
            imagePostion="left"
            style={{
              justifyContent: "center",
            }}
            imageStyle={{
              width: "30px",
              marginRight: "0px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            //position: "absolute",
            //top: 0,
            flexDirection: "column",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 15,
              border: "3px solid rgba(0, 0, 0, 0.10)",
              paddingTop: 30,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 30,
              marginTop: 20,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <div>
              <img
                alt=""
                src={QPs3_afterFreeMock}
                style={{
                  height: "200px",
                  width: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                color: "#2C6AB1",
                textAlign: "center",
                fontFamily: "Cardo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "137.4%" /* 27.48px */,
              }}
            >
              <br /> NFO Practice Test <br />
            </div>
            <div
              style={{
                color: "#2C6AB1",
                textAlign: "center",
                fontFamily: "Cardo",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "137.4%" /* 27.48px */,
                opacity: 0.7,
              }}
            >
              NFO Practice Test offers an online environment
              <br />
              mirroring the actual Olympiad. With the NFO
              <br />
              Practice Test, you can simulate exam conditions
              <br />
              with authentic question papers and timed
              <br />
              assessments. Understand your weaknesses with
              <br />
              detailed performance reports to aid preparation.
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <hr
                  style={{
                    flex: 1,
                    marginTop: "10%",
                  }}
                />

                <NFOButtons
                  onButtonClick={() => {
                    handleOpenModal("rulesModal");
                  }}
                  type={NFOButtonTypes.ImageButton}
                  buttonText="Exam Rules"
                  buttonColor="#CCE4FF"
                  textColor="rgba(44, 106, 177, 1)"
                  disableElevation={true}
                  buttonImageSrc={questionMark2}
                  imagePostion="right"
                  fontSize="12px"
                  buttonHeight="30px"
                  style={{
                    justifyContent: "center",
                    opacity: 0.9,
                  }}
                  buttonStyle={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  imageStyle={{
                    width: "30px",
                    marginRight: "0px",
                    height: "20px",
                  }}
                />

                <hr
                  style={{
                    flex: 1,
                    marginTop: "10%",
                  }}
                />
              </div>

              <NFOButtons
                type={NFOButtonTypes.GeneralButton}
                buttonText="Start New Exam"
                buttonStyle={{
                  fontSize: "13px",
                  fontWeight: "700",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                onButtonClick={handleFindUnansweredQuiz}
              />
              <div
                style={{
                  color: "#2C6AB1",
                  marginTop: "10px",
                }}
              >
                {numExamsLeft === 0 ? <a>No</a> : 12 - numExamsLeft}/12 Paper
                {numExamsLeft > 1 && <>s</>} Solved
              </div>
            </div>
          </div>
        </div>
        {numExamsTaken > 0 && (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              //position: "absolute",
              //top: 0,
              flexDirection: "column",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 15,
                border: "3px solid rgba(0, 0, 0, 0.10)",
                paddingTop: 0,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 25,
                marginTop: 20,
                marginLeft: 100,
                marginRight: 100,
              }}
            >
              <div
                style={{
                  color: "#2C6AB1",
                  textAlign: "center",
                  fontFamily: "Cardo",
                  fontSize: "16px",
                  fontStyle: "italic",
                  fontWeight: "700",
                  lineHeight: "137.4%" /* 27.48px */,
                  opacity: "0.8",
                }}
              >
                <br /> Previous Simulator Exam Score <br />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RenderPreviousScoreCards stateAllQPs={allQPs} />
              </div>
            </div>
          </div>
        )}
      </div>
      <PageFooter footerText="All rights reserved | 2023" />
      <RulesModal
        isVisible={isRulesModalOpen}
        onClose={(value: boolean) => {
          handleCloseModal("rulesModal");
        }}
      />
      <WarningModal
        onPress={handleBeginMockQuiz}
        isVisible={isWarningModalOpen}
        onClose={(value: boolean) => {
          handleCloseModal("warningModal");
        }}
      />
      <NotifyForUpcomingModal
        primaryButtonText="Cancel and Close"
        secondaryButtonText="ok, notify me"
        headerText="You have tried all the question papers."
        isVisible={isNotifyModalOpen}
        onClickPrimaryButton={() => {
          handleCloseNotifyModal();
        }}
        onClickSecondaryButton={handleSubscribeForUpcoming}
      />
      <PurchaseModal
        primaryButtonText="Cancel and Close"
        secondaryButtonText="Buy 11 Question Papers"
        headerText="Buy 11 more Question papers on mock-Olympiad"
        isVisible={isPurchaseModalOpen}
        onClickPrimaryButton={() => {
          handleClosePurchaseModal();
        }}
        onClickSecondaryButton={handleCheckoutNavigate}
      />
    </div>
  );
};

export default SubscribedInfo;
