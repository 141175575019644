import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import PdfPreview from "./Pages/PdfPreview/PdfPreview";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Info from "./Pages/Info/Info";
import Quiz from "./Pages/Quiz/Quiz";
import Complete from "./Pages/Complete/Complete";
import SubscribedInfo from "./Pages/OnSubscription/subscribedInfo";

import { supabase } from "./Utils/api/supabaseClient";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import InvalidSession from "./Pages/InvalidSession/InvalidSession";
import ScreenSizeLimitOverlay from "./Components/ScreenSizeLimitOverlay";
import { ExamResult } from "./Utils/DefinitionsInterfaces";
import BreakdownSection from "./Pages/Complete/components/BreakdownSection";
import { tokenAwsHardcoded } from "./Utils/constants";
import { getDetails } from "./Utils/api/auth";

const tokenAws = tokenAwsHardcoded;

export const AuthContext = createContext({} as any);

export const createNewSession = async (authUuid: string) => {
  // fetch existing session
  const { data: session_data, error } = await supabase // x2
    .from("user_session")
    .select("*")
    .eq("uuid", authUuid);

  const sessionId = uuidv4();
  sessionStorage.setItem("s_id", sessionId);
  if (!session_data?.length) {
    // create new session
    const { data, error } = await supabase
      .from("user_session")
      .insert([{ uuid: authUuid, session_id: sessionId }])
      .select();
  } else {
    // update with new session
    const { data, error } = await supabase
      .from("user_session")
      .update({ session_id: sessionId })
      .eq("uuid", authUuid)
      .select();
  }
};

//@ts-ignore
const AuthProvider = ({ children }) => {
  const [authAws, setAuthAws] = useState(tokenAws);
  const [stateQuizAnalysis, setStateQuizAnalysis] = useState<ExamResult>({
    questionsAttempted: 0,
    totalCorrectAnswerGiven: 0,
    totalQuestion: 0,
    questions: [],
    percentage: 0,
    examsLeft: 0,
    timeTaken: "30:00",
  });
  const [quizTimeTaken, setQuizTimeTaken] = useState("");
  const [auth, setAuth] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [invalidSession, setInvalidSession] = useState(false);
  const setParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("token");
    const userName = urlParams.get("userName");
    if (accessToken && userName) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("userName", userName);
    }
    const localToken = localStorage.getItem("accessToken");
    const localuserName = localStorage.getItem("userName");
    return { localToken, localuserName };
  };

  const getData = async (uuid: string) => {
    try {
      let { data: user_data_login } = await getDetails(uuid);
      return user_data_login;
    } catch (err) {
      return null;
    }
  };

  const checkExistingSession = async () => {
    const { localToken, localuserName } = setParams();
    if (localToken && localuserName) {
      const data = await getData(localuserName);
      console.log(data);
      if (data) {
        //@ts-ignore
        if (data.status === "Success") {
          //@ts-ignore
          setAuth(data.data);
          setInvalidSession(false);
          console.log(invalidSession);
          //@ts-ignore
        } else if (data.status == "Error") {
          setInvalidSession(true);
        }
      }
    } else {
      setInvalidSession(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setParams();
    checkExistingSession();
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        invalidSession,
        authAws,
        setAuthAws,
        stateQuizAnalysis,
        setStateQuizAnalysis,
        quizTimeTaken,
        setQuizTimeTaken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AppRoutes = () => {
  const { auth, invalidSession } = useContext(AuthContext);
  console.log("Deployment : 06/05/24: 11:00pm");
  return auth ? (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="*" element={<Navigate to={"/subscribedInfo"} replace />} />
        <Route path="/pdf-preview" element={<PdfPreview />} />
        <Route path="/info" element={<Info />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/breakdown-section" element={<BreakdownSection />} />
        <Route path="/subscribedInfo" element={<SubscribedInfo />} />
        <Route path="/logout" element={<></>} />
      </Routes>
    </BrowserRouter>
  ) : (
    // <Login />
    <InvalidSession switchToLogin={() => {}} />
  );
};

const App = () => {
  return (
    <div>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {/* <ScreenSizeLimitOverlay /> */}
    </div>
  );
};

export default App;
