import api from "../axios/axiosMW";

export interface ServerResponseGeneral {
  status: "Success" | "Error";
  status_code: number;
  message: string;
}

export interface sendOtpResponse extends ServerResponseGeneral {}

export interface userData {
  created_at: string;
  school_id: number;
  student_username: string;
  name: string;
  phone: string;
  email: string;
  grade: number;
  batch_id: string;
  invite_sent: boolean;
  report_generated: boolean;
  exam_notification_sent: boolean;
  report_id: number;
  paid_for_mock_olympiad: boolean;
  notify_for_new_mock_olympiads: boolean;
  token: string;
  refreshToken: string;
  examDate: string;
}

export interface verifyOtpResponse extends ServerResponseGeneral {
  data: userData;
}

export interface getDetailsResponse extends ServerResponseGeneral {
  data: {
    created_at: string;
    school_id: number;
    student_username: string;
    name: string;
    phone: string;
    email: string;
    grade: number;
    batch_id: string;
    invite_sent: boolean;
    report_generated: boolean;
    exam_notification_sent: boolean;
    report_id: number;
    paid_for_mock_olympiad: boolean;
    notify_for_new_mock_olympiads: boolean;
    school: string;
    examDate: string;
  };
}

/**
 *
 * @param userId
 * @returns
 */
export function sendOtp(studentUserName: string): Promise<sendOtpResponse> {
  return api.post("user-login-send-otp", {
    studentUserName,
  });
}

/**
 *
 * @param data
 * @returns
 */
export function verifyOtp(data: {
  studentUserName: string;
  otp: string;
}): Promise<verifyOtpResponse> {
  return api.post("user-login-verify-otp", {
    ...data,
  });
}

export function getDetails(
  studentUserName: string
): Promise<getDetailsResponse> {
  return api.post("get-student-details", { studentUserName });
}

interface refreshTokenResponse extends ServerResponseGeneral {
  data: {
    token: string;
    refresh_token: string;
  };
}

export function RefreshToken(
  refreshToken: string | null
): Promise<refreshTokenResponse> {
  return api.post("refresh-token", {
    headers: {
      Authorization: refreshToken,
    },
  });
}
