import React from "react";
import NFOModals, { ModalTypes } from "../../../../Components/NFOModals";
import {
  AlertIcon,
  PurchaseQPs,
  QPs3_Olympiad,
} from "../../../../Assets/images";
import NFOButtons, { NFOButtonTypes } from "../../../../Components/NFOButtons";

interface PurchaseModalProps {
  headerText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  isVisible: boolean;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({
  headerText,
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  isVisible,
}) => {
  return (
    <NFOModals
      modalType={ModalTypes.GeneralModal}
      isVisible={isVisible}
      onClose={() => {
        onClickPrimaryButton();
      }}
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 15,
            padding: "10px",
            border: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            maxWidth: "320px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img src={PurchaseQPs} />

            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                padding: "10px",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "114.4%",
                fontWeight: "700",
              }}
            >
              {headerText}
            </div>
            <div
              style={{
                color: "rgba(44, 106, 177, 1)",
                paddingTop: "10px",
                paddingLeft: "50px",
                paddingRight: "50px",
                fontSize: "16px",
                textAlign: "center",
                lineHeight: "137.4%",
                opacity: "0.7",
              }}
            >
              Access all available question papers & boost your preparation for
              your upcoming Olympiad.
            </div>
            {/* <button
              style={{
                borderRadius: "17px",
                border: "1px solid #4179B9",
                margin: 10,
                color: "#4179B9",
              }}
            >
              View details
            </button> */}
            <br />
            <div>
              <div
                style={{
                  color: "#2C6AB1",
                  opacity: "0.9",
                }}
              >
                Only
              </div>
              <s
                style={{
                  color: "79A4D6",
                  opacity: "0.9",
                }}
              >
                ₹499
              </s>
              <a
                style={{
                  fontSize: "25px",
                  color: "#2C6AB1",
                  opacity: "0.9",
                }}
              >
                &nbsp;₹299
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NFOButtons
              onButtonClick={() => {
                onClickSecondaryButton();
              }}
              type={NFOButtonTypes.GeneralButton}
              style={{ marginTop: 0, marginBottom: 0 }}
              buttonColor="#C53111"
              buttonText={secondaryButtonText}
              fontSize="14px"
              disableElevation={true}
              buttonStyle={{
                marginTop: 0,
                width: "225px",
                backgroundColor: "#2C6AB1",
              }}
            />
            <NFOButtons
              onButtonClick={() => {
                onClickPrimaryButton();
              }}
              type={NFOButtonTypes.GeneralButton}
              buttonText={primaryButtonText}
              disableElevation={true}
              style={{ marginTop: 0, marginBottom: 0 }}
              fontSize="13px"
              buttonStyle={{
                marginTop: 0,
                paddingLeft: "15px",
                paddingRight: "15px",
                backgroundColor: "rgba(99, 101, 103, 0.15)",
                color: "#2C6AB1",
                width: "200px",
              }}
            />
          </div>
        </div>
      </>
    </NFOModals>
  );
};

export default PurchaseModal;
