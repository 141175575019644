import React from "react";
import NFOModals, { ModalTypes } from "../../../Components/NFOModals";
import NFOButtons, { NFOButtonTypes } from "../../../Components/NFOButtons";
import { AlertIcon } from "../../../Assets/images";

interface WarningModalProps {
  isVisible: boolean;
  onClose: (value: boolean) => void;
  onPress: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  isVisible,
  onClose,
  onPress,
}) => {
  const rules = [
    "Your exam consists of 25 questions.",
    "Each exam is for a duration of 30 minutes.",
    "The exam includes both multiple-choice and multiple-select questions. You will be able to find a tag below each question indicating the same.",
    "The exam will auto submit once the provided time has lapsed.",
    "Switching of tabs is not allowed during the exam. ",
  ];
  return (
    <NFOModals
      modalType={ModalTypes.GeneralModal}
      isVisible={isVisible}
      onClose={() => {
        onClose(false);
      }}
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 15,
            padding: "10px",
            border: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            maxWidth: "350px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "rgba(44, 106, 177, 1)",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Attention
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={AlertIcon}
              style={{
                height: "65px",
                width: "65px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            />
            {rules.map((item: any, index: number) => {
              return (
                <div
                  key={`${item + index}`}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      color: "rgba(44, 106, 177, 1)",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >{`${index + 1}.`}</div>
                  <div
                    style={{
                      color: "rgba(44, 106, 177, 1)",
                      padding: "10px",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >{item}</div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <NFOButtons
              onButtonClick={() => {
                onClose(true);
              }}
              type={NFOButtonTypes.GeneralButton}
              style={{ marginRight: "5px" }}
              buttonColor="#C53111"
              buttonText="Cancel"
              fontSize="13px"
              disableElevation={true}
            />
            <NFOButtons
              onButtonClick={() => {
                onPress();
              }}
              type={NFOButtonTypes.GeneralButton}
              buttonText="Start Exam"
              disableElevation={true}
              style={{ marginLeft: "5px" }}
              fontSize="13px"
              buttonStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
            />

            {/* <button className="cont">Purchase</button> */}
          </div>
        </div>
      </>
    </NFOModals>
  );
};

export default WarningModal;
